
import Swiper, { Navigation, Pagination } from 'swiper';
import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import { Grid, html } from "gridjs";

const testLog = (string) => {
  console.log(string);
}


function init() {

  Alpine.plugin(collapse);
  window.Alpine = Alpine;
  Alpine.start();
  console.log("hello");
  const subTable = document.getElementById("sub-table-wrapper");

  initTable(subTable);

  testLog("ES6 baby!!");

}

function initTable(t) {
    if (t) {
      new Grid({
      columns: [
        { 
          name: 'Spot Number',
          formatter: (cell) => html(`${cell}`),
          sort: false
        },
        "Slip Size", 
        "Monthly Rate - due on the 1st of each month",
        "Yearly Rate - 10% discount on yearly reservations",
        { 
          name: ' ',
          formatter: (cell) => html(`${cell}`),
          sort: false
        },
      ],
      pagination: {
        limit: 10,
        summary: true
      },
      data: window.spotsArray
    }).render(t);
    }
}

window.addEventListener("load", function () {
  init(); //
  // window.Fancybox = Fancybox;

});
